import Vue from 'vue'
import App from './App.vue'
import {initRouter} from './router'
import './theme/index.less'
import Antd from 'ant-design-vue'
import Viser from 'viser-vue'
import '@/mock'
import store from './store'
import 'animate.css/source/animate.css'
import Plugins from '@/plugins'
import {initI18n} from '@/utils/i18n'
import bootstrap from '@/bootstrap'
import 'moment/locale/zh-cn'
import Print from 'vue-print-nb'
const router = initRouter(store.state.setting.asyncRoutes)
const i18n = initI18n('CN', 'US')
import VueDND from 'awe-dnd'
Vue.use(VueDND)

Vue.use(Print)
Vue.use(Antd)
Vue.config.productionTip = false
Vue.use(Viser)
Vue.use(Plugins)

Vue.directive('no-chinese', {
  bind(el) {
    el.addEventListener('input', (event) => {
      const value = event.target.value;
      // 使用正则表达式替换掉中文字符
      event.target.value = value.replace(/[\u4e00-\u9fa5]/g, '');
      // 触发 input 事件，使得数据可以同步到 v-model
      event.target.dispatchEvent(new Event('input'));
    });
  },
});
bootstrap({router, store, i18n, message: Vue.prototype.$message})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
